import { FC, useCallback, useEffect, useState } from 'react';
import { ClientContactResponse } from '../../models/ClientContacts';
import ClientContactService from '../../services/ClientContactService';
import { useNavigate, useParams } from 'react-router';
import ProfileInitials from '../../components/shared/profile-image/ProfileInitials';
import PageLoader from '../../components/shared/page-loader/PageLoader';
import TagGroup from '../../components/tags/TagGroup';
import Button, { ButtonSize, ButtonType } from '../../components/shared/form-control/Button';
import { useTranslation } from 'react-i18next';
import ContactGeneralDetails from '../../components/contacts/ContactGeneralDetails';
import { ClientTagResponse } from '../../models/ClientTag';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import StaticBreadCrumb from '../../components/shared/breadcumb/StaticBreadCrumb';
import ClientTagService from '../../services/ClientTagService';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../../components/shared/modal/variants/StandardModal';
import { useQueryClient } from '@tanstack/react-query';
import { useCurrentClient } from '../../global-state/Clients';

const ContactEditPage: FC = () => {
  const currentClient = useCurrentClient((x) => x.value);
  const { contactId } = useParams<{ contactId: string }>();
  const [contact, setContact] = useState<ClientContactResponse | null>(null);
  const [originalTags, setOriginalTags] = useState<ClientTagResponse[]>([]);
  const [dirty, setDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const { t } = useTranslation(['organisation', 'navigation']);
  const navigate = useNavigate();

  useEffect(() => {
    ClientContactService.getContact(currentClient!.id, contactId!).then((res) => {
      setContact(res.data);
      setOriginalTags(res.data.tags);
    });
  }, [contactId, currentClient]);

  const onTagsChanged = useCallback((tags: ClientTagResponse[]) => {
    setContact((prev) => prev && { ...prev, tags });
    setDirty(true);
  }, []);

  const onContactChanged = useCallback((contact: ClientContactResponse) => {
    setContact(contact);
    setDirty(true);
  }, []);

  const save = useCallback(() => {
    if (!contact) return;

    setSaving(true);
    ClientContactService.updateContact(currentClient!.id, contact)
      .then(() => {
        // Invalidate the client contacts query to refetch the data
        queryClient.invalidateQueries({ queryKey: ['clientContacts', currentClient!.id] });
        const addedTags = contact.tags.filter((x) => !originalTags.find((o) => o.id === x.id));
        const removedTags = originalTags.filter((x) => !contact.tags.find((o) => o.id === x.id));

        const promises = addedTags
          .map((tag) => ClientTagService.linkTag(currentClient!.id, tag.id, contact.id))
          .concat(removedTags.map((tag) => ClientTagService.unlinkTag(currentClient!.id, tag.id, contact.id)));

        return Promise.all(promises);
      })
      .then((promises) => {
        if (promises.length) {
          queryClient.invalidateQueries({ queryKey: ['clientTags', currentClient!.id] });
        }

        navigate(`/clients/${currentClient!.id}/organisation#contacts`);
      })
      .finally(() => {
        setSaving(false);
      });
  }, [contact, currentClient, navigate, originalTags, queryClient]);

  const deleteContact = useCallback(() => {
    if (!contact) return;

    ClientContactService.deleteContact(currentClient!.id, contact.id).then(() => {
      queryClient.invalidateQueries({ queryKey: ['clientTags', currentClient!.id] });
      navigate(`/clients/${currentClient!.id}/organisation#contacts`);
    });
  }, [contact, currentClient, navigate, queryClient]);

  return (
    <PageLoader loading={contact === null}>
      {contact && (
        <div className="bg-background-1 h-full">
          <TopNavPortal>
            <StaticBreadCrumb
              currentStepName={`${contact.firstName || ''} ${contact.lastName || ''}`}
              breadCrumbs={[
                {
                  name: t('navigation:page.my-org'),
                  path: `/clients/${currentClient!.id}/organisation#contacts`,
                },
              ]}
            />
          </TopNavPortal>

          <div className="border-gray-2 flex justify-between gap-3 border-b bg-white px-6 py-4">
            <div className="flex flex-grow items-center gap-4">
              <ProfileInitials sizeCss="w-16 h-16 text-2xl" firstName={contact?.firstName} lastName={contact?.lastName} />
              <div>
                <div className="font-medium">
                  {contact.firstName} {contact.lastName}
                </div>
                <TagGroup tags={contact.tags} single={false} icon />
              </div>
            </div>
            <div className="flex flex-shrink-0 items-center gap-2">
              <Button type={ButtonType.TERTIARY} size={ButtonSize.S} onClick={() => setDeleteModalOpen(true)}>
                {t('organisation:contacts.edit-page.delete')}
              </Button>
              <Button size={ButtonSize.S} disabled={!dirty} onClick={save} loading={saving}>
                {t('organisation:contacts.edit-page.save')}
              </Button>
            </div>
          </div>

          <div className="mx-6 max-w-[650px] pt-2">
            <ContactGeneralDetails contact={contact} onChange={onContactChanged} onTagsChanged={onTagsChanged} />
          </div>

          <ModalContext.Provider value={{ open: deleteModalOpen, modalWidth: 'w-[500px]', onClose: () => setDeleteModalOpen(false) }}>
            <StandardModal
              title={t('organisation:contacts.delete-contact-modal.title')}
              confirmButtonTitle={t('organisation:contacts.delete-contact-modal.buttons.cancel')}
              cancelButtonTitle={t('organisation:contacts.delete-contact-modal.buttons.confirm')}
              onConfirmClick={() => setDeleteModalOpen(false)}
              onCancelClick={() => {
                setDeleteModalOpen(false);
                deleteContact();
              }}
            >
              {t('organisation:contacts.delete-contact-modal.description')}
            </StandardModal>
          </ModalContext.Provider>
        </div>
      )}
    </PageLoader>
  );
};

export default ContactEditPage;
